<template>
  <div id="userMng" v-loading.fullscreen.lock="loading" element-loading-text="请求中，请稍后" element-loading-background="rgba(255, 255, 255, 0.7)">
    <div class="c-query">
      <el-button type="primary" @click="showAddUserDialog('add')">添加用户</el-button>
    </div>
    <div class="c-container-box">
      <el-table :data="dataArr">
        <el-table-column prop="username" label="用户账号"></el-table-column>
        <!-- <el-table-column prop="password" label="密码"></el-table-column> -->
        <el-table-column label="禁用">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.disabled" @change="modifyDisabled(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-tooltip content="重置密码" placement="bottom">
              <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="showAddUserDialog('edit',scope.row.username)"></el-button>
            </el-tooltip>
            <el-tooltip content="管理设备" placement="bottom">
              <el-button type="success" icon="iconfont icon_device" circle size="mini" @click="showDeviceMngDialog(scope.row.username)"></el-button>
            </el-tooltip>
            <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="showDeleteUserDialog(scope.row.username)">
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 管理设备 -->
    <Dialog :data="deviceMngDialog" @sure="onSure">
      <div class="device-mng-dialog">
        <el-tree :data="treeData" show-checkbox node-key="id" default-expand-all ref="treeData" :expand-on-click-node="false" check-on-click-node :default-checked-keys="checkedKeys">
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <div style="color:#415058">
              <i v-show="data.type!='org'" class="iconfont icon_device"></i>&nbsp;
              <span>{{data.title?data.title:data.uid}}</span>
            </div>
          </span>
        </el-tree>
      </div>
    </Dialog>
    <!-- 添加用户/修改密码 -->
    <Dialog :data="addUserDialog" @sure="onSure">
      <template>
        <div class="add-user-dialog">
          <div class="c-item">
            <span>用户账号：</span>
            <el-input :disabled="operateType=='add'?false:true" v-model="addUserDialog.data.username">
            </el-input>
          </div>
          <div class="c-item">
            <span>密码：</span>
            <el-input v-model="addUserDialog.data.password" @change="passwordVerify"></el-input>
          </div>
          <p class="c-tips">*密码长度至少8位，由数字、字母或特殊字符中2种方式的组合</p>
        </div>
      </template>
    </Dialog>
    <!-- 删除账号 -->
    <Dialog :data="deleteUserDialog" @sure="onSure">
      <template>
        <div class="delete-user-dialog">
          您确定删除子账号 “{{curUser}}” 吗？
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '../../components/Dialog.vue';

export default {
  data() {
    return {
      dataArr: [],
      operateType: '', //操作类型：device管理设备，add添加用户，edit修改密码，del删除账号
      curUser: '', //当前操作user
      checkedKeys: [], //子账号拥有的设备
      // 管理设备dialog
      deviceMngDialog: {
        show: false,
        title: '管理设备',
        width: '500px'
      },
      // 添加用户/删除账号dialog
      addUserDialog: {
        show: false,
        title: '添加用户',
        width: '550px',
        data: {
          username: '',
          password: ''
        }
      },
      // 删除账号dialog
      deleteUserDialog: {
        show: false,
        title: '删除账号',
        width: '550px'
      },
      loading: true,
      user: ''
    }
  },
  components: {
    Dialog
  },
  computed: {
    treeData() {
      return this.$store.state.treeData;
    }
  },
  methods: {
    // 请求子账号列表
    childUsersRequest() {
      let user = this.common.cGetUser();
      let json = {
        "cmd": "childUsers"
      }
      this.ws.send(json);
      this.loading = true;
    },
    // 请求子账号拥有的设备
    childUserDevicesRequest() {
      let json = {
        "cmd": "childUserDevices",
        "user": this.curUser
      }
      this.ws.send(json);
    },
    // 设置子账号拥有的设备
    setCheckedKeys() {
      setTimeout(() => {
        this.$refs.treeData.setCheckedKeys(this.checkedKeys);
      }, 1)
    },

    // 显示管理设备dialog
    showDeviceMngDialog(username) {
      this.operateType = "device";
      this.deviceMngDialog.show = true;
      this.curUser = username;
      this.childUserDevicesRequest();
    },
    // 显示添加用户dialog
    showAddUserDialog(type, username) {
      this.operateType = type;
      let data = this.addUserDialog;
      data.show = true;
      if (type == 'add') {
        data.title = "添加用户";
        data.data = {
          username: '',
          password: ''
        }
      } else {
        this.curUser = username;
        data.title = "重置密码";
        data.data = {
          username: username,
          password: ''
        }
      }
    },
    // 显示删除账号dialog
    showDeleteUserDialog(username) {
      this.operateType = 'del';
      this.deleteUserDialog.show = true;
      this.curUser = username;
    },

    // 相关操作
    onSure() {
      switch (this.operateType) {
        case 'device':
          this.deviceMng();
          break;
        case 'add':
          this.addUser();
          break;
        case 'edit':
          this.editUser();
          break;
        case 'del':
          this.deleteUser();
          break;
        default:
          break;
      }
    },
    // 管理设备
    deviceMng() {
      let checkedNodes = this.$refs.treeData.getCheckedNodes();
      if (checkedNodes.length == 0) {
        this.common.cToast(this, '至少分享一台设备');
        return;
      }
      let uidArr = new Array();
      checkedNodes.forEach((item) => {
        if (item.type == "device") {
          uidArr.push(item.id);
        }
      })
      let json = {
        "cmd": "setChildUserDevices",
        "user": this.curUser,
        "devices": uidArr
      }
      this.ws.send(json);
      this.loading = true;
    },
    // 账号密码验证
    userVerify() {
      let data = this.addUserDialog.data;
      // 不能为空
      if (data.username == "" || data.password == "") {
        this.common.cToast(this, "账号或密码不能为空");
        return false;
      }
      // 长度16
      if (data.username.length > 16 || data.password.length > 16) {
        this.common.cToast(this, "账号或密码长度不能超过16个字节");
        return false;
      }
      //密码强度校验
      if (!this.common.cIsPassword(data.password)) {
        this.common.cToast(this, "密码最少8位，需要包含数字、字母或特殊字符至少两种组合");
        return false
      }
      // 只能输入英文字母和数字
      let reg = /[^\w\.\/]/ig;
      if (reg.test(data.username)) {
        this.common.cToast(this, "账号只能包含字母或数字");
        return false;
      }

      return true;
    },
    //密码强度验证
    passwordVerify() {
      let password = this.addUserDialog.data.password
      let isVerify = this.common.cIsPassword(password)
      if (!isVerify) {
        this.common.cToast(this, "密码最少8位，需要包含数字、字母或特殊字符至少两种组合");
      }
    },
    // 添加用户
    addUser() {
      if (!this.userVerify()) {
        return;
      }
      let data = this.addUserDialog.data;
      let json = {
        "cmd": "registerUser",
        "user": data.username,
        "password": data.password,
        "isManager": this.user.isAdmin == "1" ? "1" : "0"
      }
      this.ws.send(json);
      this.loading = true;
    },
    // 重置密码
    editUser() {
      if (!this.userVerify()) {
        return;
      }
      let data = this.addUserDialog.data;
      let json = {
        "cmd": "setChildUserPassword",
        "user": data.username,
        "password": data.password
      }
      this.ws.send(json);
      this.loading = true;
    },
    // 更改账号禁用状态
    modifyDisabled(data) {
      this.curUser = data.username;
      // let command = data.disabled ? "enableChildUser" : "disableChildUser";
      let command = data.disabled ? "disableChildUser" : "enableChildUser";

      let json = {
        "cmd": command,
        "user": data.username
      }
      this.ws.send(json);
      this.loading = true;
    },
    // 删除账号
    deleteUser() {
      let json = {
        "cmd": "removeChildUser",
        "user": this.curUser
      }
      this.ws.send(json);
      this.loading = true;
    }
  },
  mounted() {
    this.user = this.common.cGetUser();

    // 获取子账号列表
    this.childUsersRequest();

    // 子账号列表result
    this.ws.addCallback('childUsersAck', (res) => {
      this.loading = false;
      if (!this.common.cGetResult(res)) {
        return;
      }
      let users = new Array();
      for (let item of res.users) {
        users.push({
          username: item.user,
          password: '',
          disabled: item.disabled
        })
      }
      this.dataArr = users;
      console.log(111, res.users);
    })

    // 添加用户result
    this.ws.addCallback('registerUserAck', (res) => {
      this.loading = false;
      if (res.error != 0) {
        if (res.errorName == "EUserExists") {
          this.common.cToast(this, "该账号已被注册！");
        } else {
          this.common.cResultNo(this);
        }
        return;
      }
      this.common.cResultOk(this);
      this.addUserDialog.show = false;
      this.childUsersRequest();
    })

    // 重置密码result
    this.ws.addCallback('setChildUserPasswordAck', (res) => {
      this.loading = false;
      if (this.common.cGetResult(res)) {
        this.common.cResultOk(this);
        this.addUserDialog.show = false;
      } else {
        this.common.cResultNo(this);
      }
    })

    // 更改账号禁用状态result
    const setDisabled = (res) => {
      this.loading = false;
      if (this.common.cGetResult(res)) {
        this.common.cResultOk(this);
        return;
      }
      this.common.cResultNo(this);
      this.dataArr.forEach((item) => {
        if (item.username == this.curUser) {
          item.disabled = !item.disabled;
        }
      })
    }
    this.ws.addCallback('enableChildUserAck', (res) => {
      setDisabled(res);
    })
    this.ws.addCallback('disableChildUserAck', (res) => {
      setDisabled(res);
    })

    // 删除子账号result
    this.ws.addCallback('removeChildUserAck', (res) => {
      this.loading = false;
      this.deleteUserDialog.show = false;
      if (!this.common.cGetResult(res)) {
        this.common.cResultNo(this);
        return;
      }
      this.common.cResultOk(this);
      this.childUsersRequest();
    })

    // 子账号设备列表result
    this.ws.addCallback('childUserDevicesAck', (res) => {
      if (res.devices) {
        this.checkedKeys = res.devices;
      } else {
        this.checkedKeys = [];
      }
      this.setCheckedKeys();
    })

    // 管理设备result
    this.ws.addCallback('setChildUserDevicesAck', (res) => {
      this.loading = false;
      if (!this.common.cGetResult(res)) {
        this.common.cResultNo(this);
        return;
      }
      this.common.cResultOk(this);
      this.deviceMngDialog.show = false;
    })
  }
}
</script>

<style lang="scss">
#userMng {
  .c-container-box {
    margin-top: 0;

    .icon_device {
      font-size: 13px;
    }
  }

  .device-mng-dialog {
    height: 400px;
    overflow: auto;
  }

  .add-user-dialog,
  .delete-user-dialog {
    padding: 4% 10%;
  }

  // 重写dialog样式
  .el-dialog__body {
    padding: 1% 5%;
  }
}
</style>
